import React, { Component } from "react";
import { Box, Stack, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import { handleLogout } from "Utils/HelperFunctions";
class LogoutPopup extends Component {
  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={() => this.props.handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            height: 150,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            height={"100%"}
          >
            <p className="modal-description">are you sure want to logout</p>

            <Stack
              spacing={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              className="pt-4"
            >
              <Button
                size="small"
                variant="outlined"
                className="btn-primary-outlined rounded "
                onClick={() => this.props.handleClose()}
              >
                No
              </Button>
              <Button
                size="small"
                className="btn btn-primary rounded"
                variant="contained"
                onClick={() => {
                  handleLogout();
                  window.location.href = `${window.location.origin}/auth/prelogin/login`;
                }}
              >
                Yes
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    );
  }
}

export default LogoutPopup;
