import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (params) => {
    try {
      const url = `respondent/profile`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (params) => {
    try {
      const url = `respondent/editprofile`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const deleteUserAccount = createAsyncThunk(
  "deleteUserAccount",
  async (params) => {
    try {
      const url = `respondent/deleteRespondent`;
      console.log(params)
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);


export const uploadProfile = createAsyncThunk(
  "uploadProfile",
  async (params) => {
    try {
      const url = `respondent/uploadProfileImage`;
      console.log(params)
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);