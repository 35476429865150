import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const getOnboardingQuestions = createAsyncThunk(
  "getOnboardingQuestions",
  async (params) => {
    try {
      const url = `/respondent/onBoardingQuestions`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const saveOnboardingAnswer = createAsyncThunk(
  "saveOnboardingAnswer",
  async (params) => {
    try {
      const url = `/respondent/onBoardingAnwsers`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getAllOnboardingQuestionsAnswers = createAsyncThunk(
  "getAllOnboardingQuestionsAnswers",
  async (params) => {
    try {
      const url = `/respondent/onBoardingAnwsers/getAllOnboardingQuestionsAnswers`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
