import { createSlice } from "@reduxjs/toolkit";
import { status } from "Constants";
import { addBankDetails, getBankDetails } from "./BankDetailsThunk";

const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState: {
    addBankDetailsRes: {
      status: null,
      data: {},
    },
    bankDetailsRes: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addBankDetails.pending.toString(), (state, action) => {
        return {
          ...state,
          addBankDetailsRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(addBankDetails.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          addBankDetailsRes: {
            status: status.SUCCESS,
            data: payload,
          },
        };
      })
      .addCase(addBankDetails.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          addBankDetailsRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(getBankDetails.pending.toString(), (state, action) => {
        return {
          ...state,
          bankDetailsRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getBankDetails.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          bankDetailsRes: {
            status: status.SUCCESS,
            data: payload,
          },
        };
      })
      .addCase(getBankDetails.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          bankDetailsRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })
  },
});

export default bankDetailsSlice.reducer;
