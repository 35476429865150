import React, { Component, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { connect } from "react-redux";
import { PAGE_NAME } from "Constants";
import CommonLoader from "Postlogin/Components/CommonLoader";


const Dashboard = React.lazy(() => import("Postlogin/Dashboard"));
const FillResearchProject = React.lazy(() => import("Postlogin/ResearchProjects/FillResearchProject"));
const UpdateInformation = React.lazy(() => import("Postlogin/UpdateInformation"));
const FinalizedResearchProjects = React.lazy(() => import("Postlogin/ResearchProjects/FinalizedResearchProjects"));
const ResearchProjectsAvailable = React.lazy(() => import("Postlogin/ResearchProjects/ResearchProjectsAvailable"));
const ResearchDashboard = React.lazy(() => import("Postlogin/ResearchProjects"));
const EligibleSurvey = React.lazy(() => import("Postlogin/EligibleSurveys/EligibleSurvey"));
const EligibleSurveys = React.lazy(() => import("Postlogin/EligibleSurveys"));
const Profile = React.lazy(() => import("Postlogin/Profile"));
const SurveyAvailable = React.lazy(() => import("Postlogin/SurveyAvailable"));
const FillSurvey = React.lazy(() => import("Postlogin/Survey/FillSurvey"));
const OnBoarding = React.lazy(() => import("Postlogin/OnBoarding"));
class AppLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Suspense fallback={<CommonLoader />}>
        <Routes>
          <Route
            path="*"
            element={
              <Navigate to={`/postlogin/${PAGE_NAME.DASHBOARD}`} replace={true} />
            }
          />
          <Route
            path={`/postlogin/${PAGE_NAME.DASHBOARD}`}
            element={<Dashboard />}
          />
          <Route
            path={`/postlogin/${PAGE_NAME.RESEARCH_DASHBOARD}`}
            element={<ResearchDashboard />}
          />
          <Route
            path={`/postlogin/${PAGE_NAME.RESEARCH_PROJECTS_AVAILABLE}`}
            element={<ResearchProjectsAvailable />}
          />
          <Route
            path={`/postlogin/${PAGE_NAME.FINALIZED_RESEARCH_PROJECTS}`}
            element={<FinalizedResearchProjects />}
          />
          <Route
            path="/postlogin/research-project/:id"
            element={<FillResearchProject />}
            replace={true}
            exact
          />
          <Route
            path="/postlogin/survey-preview/:surveyId/:token"
            element={<FillSurvey />}
            replace={true}
            exact
          />
          <Route
            path="/postlogin/survey/:surveyId"
            element={<FillSurvey />}
            replace={true}
            exact
          />
          <Route
            path={`/postlogin/${PAGE_NAME.ONBOARDING_QUESTION}`}
            element={<OnBoarding />}
          />
          <Route
            path={`/postlogin/${PAGE_NAME.SURVEY_AVAILABLE}`}
            element={<SurveyAvailable />}
          />
          <Route path={`/postlogin/${PAGE_NAME.PROFILE}`} element={<Profile />} />
          <Route
            path={`/postlogin/${PAGE_NAME.ELIGIBLE_SURVEYS}`}
            element={<EligibleSurveys />}
          />
          <Route
            path={`/postlogin/${PAGE_NAME.YOUR_INFORMATION}`}
            element={<UpdateInformation />}
          />
          <Route
            path={`/postlogin/${PAGE_NAME.ELIGIBLE_SURVEYS}/:surveyId/:surveyName`}
            element={<EligibleSurvey />}
            replace={true}
            exact
          />
        </Routes>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedAppLayout = connect(mapStateToProps)(AppLayout);
export { connectedAppLayout as AppLayout };
