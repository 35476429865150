import { LOCALSTORAGE_ITEMS } from "Constants";

export const TokenService = {
  saveToken: (token) => {
    localStorage.setItem(LOCALSTORAGE_ITEMS.authToken, token);
  },
  getToken: () => {
    return localStorage.getItem(LOCALSTORAGE_ITEMS.authToken);
  },
  deleteToken: () => {
    return localStorage.removeItem(LOCALSTORAGE_ITEMS.authToken);
  },
};

export const LocalStorageService = {
  saveData: (key, value) => {
    if (value) {
      localStorage.setItem(key, value);
    }
  },
  getData: (key) => {
    return localStorage.getItem(key);
  },
  deleteData: (key) => {
    localStorage.removeItem(key);
  },
  updateKeyVal: (key, value) => {
    let userData = LocalStorageService.getData(LOCALSTORAGE_ITEMS.userData);
    if (userData) {
      userData = JSON.parse(userData);
      userData[key] = value;
      LocalStorageService.saveData(
        LOCALSTORAGE_ITEMS.userData,
        JSON.stringify(userData)
      );
      return true;
    }
    return false;
  },
};

export function handleLogout() {
  LocalStorageService.deleteData(LOCALSTORAGE_ITEMS.authToken);
  LocalStorageService.deleteData(LOCALSTORAGE_ITEMS.userData);
}

export const ValidationEngine = {
  EMAIL_REGEX: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  PASSWORD_REGEX:
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/,
  MOBILE_NUMBER_REGEX: /^\d{10}$/,
  US_MOBILE_NUMBER_REGEX:
    /^[\+]?[0-9]{0,3}\W?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  NUMBER_ONLY_REGEX: /^[0-9]+$/,
  DECIMAL_ALLOW_REGEX: /^\d*\.?\d*$/,
  ONLY_TWO_DECIMAL_ALLOW_REGEX: /^(?:\d*\.\d{1,2}|\d+)$/,
  type: {
    MANDATORY: 0,
    REGEX: 1,
    CHARACTERCOUNT: 2,
    OPTIONAL: 3, //This is to be used when item is not mandatory but other validation is added if entered.
    MIN_VALUE: 4,
    MAX_VALUE: 5,
  },
  validate: (validationJSON, data) => {
    function validateMandatory(validationObject, value) {
      if (!value || (value && !value.trim())) {
        return {
          isValid: false,
          error: {
            isValid: false,
            message: validationObject.message,
          },
        };
      }
      return {
        isValid: true,
        error: {
          isValid: true,
          message: "",
        },
      };
    }

    function validateMinValue(validationObject, value, minValue) {
      if (
        value &&
        value.length !== validationObject.type &&
        minValue > +value
      ) {
        return {
          isValid: false,
          error: {
            isValid: false,
            message: validationObject.message,
          },
        };
      }
      return {
        isValid: true,
        error: {
          isValid: true,
          message: "",
        },
      };
    }

    function validateMaxValue(validationObject, value, maxValue) {
      if (
        value &&
        value.length !== validationObject.type &&
        maxValue < +value
      ) {
        return {
          isValid: false,
          error: {
            isValid: false,
            message: validationObject.message,
          },
        };
      }
      return {
        isValid: true,
        error: {
          isValid: true,
          message: "",
        },
      };
    }

    function validateCharacterCount(validationObject, value) {
      if (value && value.length !== validationObject.CharacterCout) {
        return {
          isValid: false,
          error: {
            isValid: false,
            message: validationObject.message,
          },
        };
      }
      return {
        isValid: true,
        error: {
          isValid: true,
          message: "",
        },
      };
    }

    function validateRegex(validationObject, value) {
      if (validationObject.regex && !validationObject.regex.test(value)) {
        return {
          isValid: false,
          error: {
            isValid: false,
            message: validationObject.message,
          },
        };
      }
      return {
        isValid: true,
        error: {
          isValid: true,
          message: "",
        },
      };
    }

    const keys = Object.keys(validationJSON);
    const errors = {};
    let isValid = true;
    let errorObj;
    keys.forEach((key) => {
      const validationArray = validationJSON[key];
      const value = data[key];
      for (const validationObj of validationArray) {
        if (validationObj.type === ValidationEngine.type.MANDATORY) {
          errorObj = validateMandatory(validationObj, value);
        } else if (
          validationObj.type === ValidationEngine.type.OPTIONAL &&
          !value
        ) {
          errorObj = {
            isValid: true,
            error: {
              isValid: true,
              message: "",
            },
          };
        } else if (
          validationObj.type === ValidationEngine.type.CHARACTERCOUNT
        ) {
          errorObj = validateCharacterCount(validationObj, value);
        } else if (validationObj.type === ValidationEngine.type.MIN_VALUE) {
          errorObj = validateMinValue(
            validationObj,
            value,
            validationObj.value
          );
        } else if (validationObj.type === ValidationEngine.type.MAX_VALUE) {
          errorObj = validateMaxValue(
            validationObj,
            value,
            validationObj.value
          );
        } else if (
          validationObj.type === ValidationEngine.type.REGEX &&
          value
        ) {
          errorObj = validateRegex(validationObj, value);
        }
        errors[key] = errorObj.error;
        isValid = isValid && errorObj.isValid;
        if (!errorObj.isValid) {
          break;
        }
      }
    });
    errors.isValid = isValid;
    return errors;
  },
};
