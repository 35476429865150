import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const addBankDetails = createAsyncThunk(
  "addBankDetails",
  async (params) => {
    try {
      const url = `respondent/addbankdetails`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);



export const getBankDetails = createAsyncThunk(
  "getBankDetails",
  async (params) => {
    try {
      const url = `respondent/getbankdetails`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);