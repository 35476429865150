import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const getAllMeta = createAsyncThunk("getAllMeta", async () => {
  try {
    const url = `respondent/researchMeta/getAllMeta`;
    const response = await postLoginService.get(url);
    return response;
  } catch (error) {
    throw error.response?.data;
  }
});

export const saveMetaForRespondent = createAsyncThunk(
  "saveMetaForRespondent",
  async (params) => {
    try {
      const url = `respondent/researchMeta/saveMetaForRespondent`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getMetaForRespondent = createAsyncThunk(
  "getMetaForRespondent",
  async () => {
    try {
      const url = `respondent/researchMeta/getMetaForRespondent`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getProspectResearchProjectsForRespondent = createAsyncThunk(
  "getProspectResearchProjectsForRespondent",
  async () => {
    try {
      const url = `respondent/researchProject/getProspectResearchProjectsForRespondent`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getResearchProjectDetailsById = createAsyncThunk(
  "getResearchProjectDetailsById",
  async (id) => {
    try {
      const url = `respondent/researchProject/getResearchProjectDetails/${id}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const saveResearchProjectScreeningQuestion = createAsyncThunk(
  "saveResearchProjectScreeningQuestion",
  async (params) => {
    try {
      const url = `respondent/researchProject/saveResearchProjectScreeningQuestion`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getDashboardDetailsForResearchProject = createAsyncThunk(
  "getDashboardDetailsForResearchProject",
  async () => {
    try {
      const url = `respondent/researchProject/getDashboardDetailsForResearchProject`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getFinalizedResearchProjectForRespondent = createAsyncThunk(
  "getFinalizedResearchProjectForRespondent",
  async (id) => {
    try {
      const url = `respondent/researchProject/getFinalizedResearchProjectForRespondent`;
      const response = await postLoginService.get(url, { respondentId: id });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);


export const uploadIntroVideo = createAsyncThunk(
  "uploadIntroVideo",
  async (params) => {
    try {
      const url = `respondent/uploadIntroVideo`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);