import { createSlice } from "@reduxjs/toolkit";
import {
  getOnboardingQuestions,
  saveOnboardingAnswer,
  getAllOnboardingQuestionsAnswers,
} from "./OnboardingThunk";
import { status } from "Constants";

const OnboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    allOnBoardingQuestions: {
      status: null,
      data: [],
    },
    onboardingAnsData: {
      status: null,
      data: {},
    },
    getAllOnboardingQuestionsAnswersRes: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOnboardingQuestions.pending.toString(), (state, action) => {
        return {
          ...state,
          allOnBoardingQuestions: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getOnboardingQuestions.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            allOnBoardingQuestions: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getOnboardingQuestions.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            allOnBoardingQuestions: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(saveOnboardingAnswer.pending.toString(), (state, action) => {
        return {
          ...state,
          onboardingAnsData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        saveOnboardingAnswer.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            onboardingAnsData: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(saveOnboardingAnswer.rejected.toString(), (state, action) => {
        return {
          ...state,
          onboardingAnsData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      })

      .addCase(
        getAllOnboardingQuestionsAnswers.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getAllOnboardingQuestionsAnswersRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getAllOnboardingQuestionsAnswers.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getAllOnboardingQuestionsAnswersRes: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getAllOnboardingQuestionsAnswers.rejected.toString(),
        (state, action) => {
          return {
            ...state,
            getAllOnboardingQuestionsAnswersRes: {
              status: status.FAILURE,
              data: action.error,
            },
          };
        }
      );
  },
});

export default OnboardingSlice.reducer;
