import React, { Component } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import PollIcon from "@mui/icons-material/Poll";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { AccountBalance } from "@mui/icons-material";
import { handleLogout } from "Utils/HelperFunctions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, IconButton } from "@mui/material";
import { PAGE_NAME } from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import Logo from "assets/img/logo.png";
import UpdateIcon from "@mui/icons-material/Update";
import Pullable from "react-pullable";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LogoutPopup from "./LogoutPopup";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
class Header extends Component {
  constructor() {
    super();
    this.state = {
      surveys: [],
      isPreview: false,
      open: false,
      logoutStatus: false,
      isReload: false,
    };
  }

  componentDidMount = () => {
    const url = window.location.href;
    if (url.includes("survey-preview")) {
      this.setState({ isPreview: true });
    }
  };

  handleRefresh = () => {
    this.setState({
      isReload: true,
    });
    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState({
          isReload: false,
        });
        window.location.reload();
        resolve();
      }, 500);
    });
  };

  sideMenuItems = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: PAGE_NAME.DASHBOARD,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.DASHBOARD}`),
    },
    {
      label: "Available Surveys",
      icon: <PollIcon />,
      key: PAGE_NAME.SURVEY_AVAILABLE,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.SURVEY_AVAILABLE}`),
    },
    // {
    //   label: "Eligible Surveys",
    //   icon: <PollIcon />,
    //   key: PAGE_NAME.ELIGIBLE_SURVEYS,
    //   onClick: () =>
    //     this.props.navigate(`/app/postlogin/${PAGE_NAME.ELIGIBLE_SURVEYS}`),
    // },
    // {
    //   label: "Onboarding ",
    //   icon: <PsychologyAltIcon />,
    //   key: PAGE_NAME.ONBOARDING_QUESTION,
    //   onClick: () =>
    //     this.props.navigate(`/app/postlogin/${PAGE_NAME.ONBOARDING_QUESTION}`),
    // },
    // {
    //   label: "Research Dashboard",
    //   icon: <DashboardCustomizeIcon />,
    //   key: PAGE_NAME.RESEARCH_DASHBOARD,
    //   onClick: () =>
    //     this.props.navigate(`/app/postlogin/${PAGE_NAME.RESEARCH_DASHBOARD}`),
    // },
    {
      label: "Research Projects",
      icon: <ManageSearchIcon />,
      key: PAGE_NAME.RESEARCH_PROJECTS_AVAILABLE,
      onClick: () =>
        this.props.navigate(
          `/app/postlogin/${PAGE_NAME.RESEARCH_PROJECTS_AVAILABLE}`
        ),
    },
    {
      label: "Your Information",
      icon: <UpdateIcon />,
      key: PAGE_NAME.YOUR_INFORMATION,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.YOUR_INFORMATION}`),
    },

    {
      label: "Profile",
      icon: <PersonIcon />,
      key: PAGE_NAME.PROFILE,
      onClick: () => this.props.navigate(`/app/postlogin/${PAGE_NAME.PROFILE}`),
    },
    {
      label: "Logout",
      icon: <LogoutIcon />,
      onClick: () => {
        this.setState({
          logoutStatus: true,
        });
      },
    },
  ];

  toggleDrawer = (open) => {
    this.setState({ open });
  };

  DrawerList = () => {
    let details = this.props?.data || {};
    return (
      <Box
        className="side-drawer"
        role="presentation"
        onClick={() => this.toggleDrawer(false)}
      >
        <div className="drawer-logo">
          <a href="">
            <img src={Logo} alt="" />
          </a>
        </div>
        <List>
          {this.sideMenuItems.map((item, index) => (
            <ListItem key={item.label} disablePadding>
              <ListItemButton
                className={`list-btn ${
                  details.page === item.key ? "active" : ""
                }`}
                onClick={item.onClick}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box className="app-versions">
          <a href="#">v1.0.0</a>
        </Box>
      </Box>
    );
  };
  handleOpen = () => {
    this.setState({ logoutStatus: true });
  };

  handleClose = () => {
    this.setState({ logoutStatus: false });
  };
  render() {
    let details = this.props?.data || {};
    const { isPreview } = this.state;
    return (
      <>
        {/* <Pullable
          onRefresh={this.handleRefresh}
          fadeSpinner={true}
          centerSpinner={true}
          rotateSpinner={true}
          spinnerSize={30}
          spinnerColor="#4047d4"
        >
          <div className="pullable">
            <div className="header-container">
              <div className="header-inner-container">
                {!isPreview && (
                  <div className="d-flex align-items-center justify-content-start">
                    {[PAGE_NAME.SURVEY, PAGE_NAME.TERMS_AND_CONDITIONS].some(
                      (page) => page === details.page
                    ) ? (
                      <button className="icon-btn rounded-circle">
                        <ArrowBackIcon
                          onClick={() => {
                            if (details?.backUrl) {
                              this.props.navigate(details.backUrl);
                            }
                          }}
                        />
                      </button>
                    ) : (
                      <button
                        className="icon-btn rounded-circle"
                        aria-label="open drawer"
                        onClick={() => this.toggleDrawer(true)}
                        edge="start"
                        sx={[this.state.open && { display: "none" }]}
                      >
                        <MenuIcon />
                      </button>
                    )}
                  </div>
                )}
                <h3 className="d-inline-block mb-0 text-left">
                  {details?.header}
                </h3>
                <Button>reload</Button>
                <Drawer
                  open={this.state.open}
                  onClose={() => this.toggleDrawer(false)}
                >
                  {this.DrawerList()}
                </Drawer>
              </div>
            </div>
          </div>
        </Pullable> */}

        <div className="pullable">
          <div className="header-container">
            <div className="header-inner-container">
              {!isPreview && (
                <div className="d-flex align-items-center justify-content-start">
                  {[PAGE_NAME.SURVEY, PAGE_NAME.TERMS_AND_CONDITIONS].some(
                    (page) => page === details.page
                  ) ? (
                    <button className="icon-btn rounded-circle">
                      <ArrowBackIcon
                        onClick={() => {
                          if (details?.backUrl) {
                            this.props.navigate(details.backUrl);
                          }
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      className="icon-btn rounded-circle"
                      aria-label="open drawer"
                      onClick={() => this.toggleDrawer(true)}
                      edge="start"
                      sx={[this.state.open && { display: "none" }]}
                    >
                      <MenuIcon />
                    </button>
                  )}
                </div>
              )}
              <h3 className="d-inline-block mb-0 text-left">
                {details?.header}
              </h3>

              {this.state.isReload ? (
                <Button
                  className="reload-icon-btn"
                  variant="contained"
                  size="small"
                  startIcon={
                    <ReplayOutlinedIcon
                      className="reload-icon"
                      style={{
                         animation: "spin 1s linear infinite",
                      }}
                    />
                  }
                />
              ) : (
                <Button
                  className="reload-icon-btn"
                  variant="contained"
                  onClick={() => this.handleRefresh()}
                  size="small"
                  startIcon={<ReplayOutlinedIcon className="reload-icon" />}
                />
              )}
              <Drawer
                open={this.state.open}
                onClose={() => this.toggleDrawer(false)}
              >
                {this.DrawerList()}
              </Drawer>
            </div>
          </div>
        </div>
        <LogoutPopup
          open={this.state.logoutStatus}
          handleOpen={() => this.handleOpen()}
          handleClose={() => this.handleClose()}
        />
        <style>
          {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(-360deg);
            }
          }
        `}
        </style>
      </>
    );
  }
}

export default navigateRouter(Header);
